<template>
  <b-overlay
    :show="isLoading"
    rounded = "lg"
    opacity="0.6"
    spinner-variant="primary"
  >
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="mt-4">
      <i class="fa fa-backward"></i>
      <router-link :to="{name: listPath[ebooks.item_type]}" class="text-capitalize">
        {{ebooks.item_type}}
      </router-link>
      <h3 class="col text-center"  v-if="!ebooks.id && !isLoading">No Item Found</h3>
      <h1 class="text-center" v-else>{{ ebooks.item_type.toUpperCase() }} 'S ITEM</h1>
    </div>
    <b-card class="p-2" id="detil-item">
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Name</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.name }}
        </b-col>
      </b-row>
      <b-row class="mt-2"> 
        <b-col class="font-weight-bold" md="3" lg="2">Edition Code</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.edition_code }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Vendor</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.brands ? ebooks.brands.vendor.name : ''}} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Authors</b-col>
        <b-col offset="1" offset-md="0">
          {{ listObjectToString(ebooks.authors, 'name') }} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Brands</b-col>
        <b-col offset="1" offset-md="0">
          <b>#{{ ebooks.brand_id }}</b>
          {{ ebooks.brands ? ebooks.brands.name : ''}} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Release Date</b-col>
        <b-col offset="1" offset-md="0">
          {{ datetime24H(ebooks.release_date) }} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Release Schedule</b-col>
        <b-col offset="1" offset-md="0">
          {{ datetime24H(ebooks.release_schedule) }} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Categories</b-col>
        <b-col offset="1" offset-md="0">
          {{ listObjectToString(ebooks.categories, 'name') }} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Active</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.is_active ? '&#9989;' : '&#10060;' }} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Internal Content</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.is_internal_content ? '&#9989;' : '&#10060;' }} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Retail</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.category_ax ? (ebooks.category_ax.is_retail ? '&#9989;' : '&#10060;') : '' }} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Parental Control</b-col>
        <b-col offset="1" offset-md="0">
          {{parentalControl}} ({{descParentalControl}})
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="!isProfileVendor">
        <b-col class="font-weight-bold" md="3" lg="2">Category AX</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.category_ax ? ebooks.category_ax.category_ax : '-' }} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Status</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.item_status}} 
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Description</b-col>
        <b-col offset="1" offset-md="0" v-html="ebooks.description">
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Current File</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.file_size | fileSizeFormat }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Content Type</b-col>
        <b-col offset="1" offset-md="0">
          {{ ebooks.content_type }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="3" lg="2">Link Preview</b-col>
        <b-col offset="1" offset-md="0" v-if = "ebooks.item_status == 'ready for consume'">
          <a :href="urlCatalog(ebooks)" target="_blank" rel="noopener noreferrer">{{urlCatalog(ebooks)}}</a>
        </b-col>
        <b-col offset="1" offset-md="0" v-else>
          <span> Link preview not yet showing up, because not ready for consume </span>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="text-center mt-2">
          <b-button variant="outline-primary" @click="goToEdit">Edit Detail</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card id="cover-preview">
      <b-row id="row-cover">
        <h3 class="col-12" style="z-index: -1;">Cover</h3>
        <b-col class="text-center">
          <b-card-img title="image_normal"
            :src="basePathCover+removeBucket(ebooks.image_normal)+'?'+now" 
            class="img-sm text-center rounded cover m-2"></b-card-img>
          <b-card-img title="image_highres"
            :src="basePathCover+removeBucket(ebooks.image_highres)+'?'+now" 
            class="img-sm text-center rounded cover m-2"></b-card-img>
          <b-card-img title="thumb_image_normal"
            :src="basePathCover+removeBucket(ebooks.thumb_image_normal)+'?'+now" 
            class="img-sm text-center rounded cover m-2"></b-card-img>
          <b-card-img title="thumb_image_highres"
            :src="basePathCover+removeBucket(ebooks.thumb_image_highres)+'?'+now" 
            class="img-sm text-center rounded cover m-2"></b-card-img>
        </b-col>
      </b-row>
      <b-row id="btn-edit-cover"
        class="pt-2 justify-content-center">
        <router-link 
          :to="{name: EditCoverItemPath.name, params: {id: ebooks.id}}" 
          class="btn btn-outline-primary"
        >Edit Cover</router-link>
      </b-row>
      <div class="text-center mt-2"> <span>Max Upload 10MB</span></div>
      <hr>
      <div id="row-preview" v-if="isShowPreview()">
        <b-row>
          <h3 class="col-12" style="z-index: -1;">Preview</h3>
        </b-row>
        <span v-for="page in ebooks.preview_page" :key="page">
          <b-card-img
            :src="srcPreview(page)+'?'+now"  
            alt="Image"
            @click="selectedPreview(page)"
            class="img-sm shadow rounded m-2">
          </b-card-img>
        </span>
        <b-row id="btn-edit-preview"
          class="pt-2 justify-content-center"
          v-if="ebooks.preview_page?.length">
          <router-link 
            :to="{name: EditPreviewItemPath.name, params: {id: ebooks.id}}" 
            class="btn btn-outline-primary"
          >Edit Preview</router-link>
        </b-row>
      </div>
    </b-card>
    <b-card id="item-ax" v-if="!isProfileVendor">
      <h4 class="card-title text-center h3 p-1"> Item AX </h4>
      <hr> 
      <b-row class="mt-4">
        <b-col class="font-weight-bold" md="6" lg="2">Premium Ax :</b-col>
        <b-col offset="1" offset-md="0">
          {{ ItemSoaxPremium?.premium_idax ? ItemSoaxPremium?.premium_idax : "Don't have premium ax yet" }}
        </b-col>
        <b-col class="font-weight-bold" md="6" lg="2">Single Ax :</b-col>
        <b-col offset="1" offset-md="0">
          {{ ItemSoax ? ItemSoax.single_idax  : "Don't have single ax yet" }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="6" lg="2">Base Price :</b-col>
        <b-col offset="1" offset-md="0">
          {{ ItemSoaxPremium?.base_price ? ItemSoaxPremium?.base_price : "Don't base price yet" }}
        </b-col>
        <b-col class="font-weight-bold" md="6" lg="2">Base Price :</b-col>
        <b-col offset="1" offset-md="0">
          {{ ItemSoax?.base_price ? ItemSoax?.base_price : "Don't base price yet" }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="6" lg="2">Vendor :</b-col>
        <b-col offset="1" offset-md="0">
          {{ ItemSoaxPremium?.vendor ? ItemSoaxPremium?.vendor : "Don't have vendor yet" }}
        </b-col>
        <b-col class="font-weight-bold" md="6" lg="2">Vendor :</b-col>
        <b-col offset="1" offset-md="0">
          {{ ItemSoax?.vendor ? ItemSoax?.vendor : "Don't have vendor yet" }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="font-weight-bold" md="6" lg="2">Vendor Ax :</b-col>
        <b-col offset="1" offset-md="0">
          {{ ItemSoaxPremium?.vendor_id_ax ? ItemSoaxPremium?.vendor_id_ax : "Don't have vendor ax yet"}}
        </b-col>
        <b-col class="font-weight-bold" md="6" lg="2">Vendor Ax :</b-col>
        <b-col offset="1" offset-md="0">
          {{ ItemSoax?.vendor_id_ax ? ItemSoax?.vendor_id_ax : "Don't have vendor ax yet"}}
        </b-col>
      </b-row>
      <div class="row mt-5">
        <div class="col-md-6">
          <b-button variant="primary" v-b-modal.modal-2>Update Premium AX</b-button>
        </div>
        <div class="col-md-6">
          <b-button variant="primary" v-b-modal.modal-1>Update Single AX</b-button>
        </div>
      </div>
    </b-card>
    <b-card v-if="ebooks.item_type == item_types_audio"  id ="chapter-audio">
      <h4 class="card-title text-center h3 p-1"> Chapter [Audio Only] </h4>
      <b-table
        :fields="fieldsAudio"
        hover
        responsive="sm"
        :striped="true"
        :items="audioItem"
        :show-empty=true
      >
      <template #cell(is_active)="row">
        <div>
          <span v-if="row.item.is_active" class="badge">&#9989;</span>
          <span v-else class="badge" style="color:red"> &#10005; </span>
        </div>
      </template>
      <template #cell(file_size)="row">
        <div>
          {{ row.item.file_size | fileSizeFormat }}
        </div>
      </template>
      </b-table>
      <b-col v-if="ebooks.item_status=='ready for upload'">
        <b-button variant="primary"  class="font-weight-bold" md="6" lg="2" @click="actionEditChapter" >Edit Chapter</b-button>
      </b-col>
      <b-col v-else>
        <b-button variant="primary"  class="font-weight-bold" md="6" lg="2" @click="actionEditChapter" >View Chapter</b-button>
      </b-col>
    </b-card>
    <b-card id="offer-single">
      <h4 class="card-title text-center h3 p-1"> Offers Single </h4>
      <b-table
        :fields="fields"
        hover
        responsive="sm"
        :striped="true"
        :items="ebooks.single"
        :show-empty=true
      >
      <template #cell(name)="row">
        <router-link :to="{name: SingleEditPath.name, params: {id: row.item.id}}">
          {{row.item.name}}
        </router-link>
      </template>
      <template v-slot:cell(is_free)="row">
        <div>
          <span v-if="row.item.is_free" class="badge">&#9989;</span>
          <span v-else class="badge" style="color:red"> &#10060; </span>
        </div>
      </template>
      
      <template v-slot:cell(is_active)="row">
        <div>
          <span v-if="row.item.is_active" class="badge">&#9989;</span>
          <span v-else class="badge" style="color:red"> &#10005; </span>
        </div>
      </template>
      <template v-slot:cell(offer_status)="row">
        <div>
          <span v-if="row.item.offer_status =='8'" class="badge badge-danger">  Not For Sale</span>
          <span v-else class="badge badge-success">  Ready For Sale </span>
        </div>
      </template>
      
      </b-table>
    </b-card>
    <b-card id="offer-buffets">
      <h4 class="card-title h3 text-center p-1"> Offers Buffets </h4>
      <b-table
        hover
        :fields="fields"
        responsive="sm"
        :items="ebooks.buffets"
        :show-empty=true
      >
      <template #cell(name)="row">
        <span v-if="row.item.offer_type_id==2">
          <router-link :to="{name: SubscriptionEditPath.name, params: {id: row.item.id}}">
            {{row.item.name}}
          </router-link>
        </span>
        <span v-else>
          <router-link :to="{name: PremiumEditPath.name, params: {id: row.item.id}}">
          {{row.item.name}}
        </router-link>
      </span>
      </template>
      
      <template v-slot:cell(is_free)="row">
        <div>
          <span v-if="row.item.is_free" class="badge">&#9989;</span>
          <span v-else class="badge" style="color:red"> &#10060; </span>
        </div>
      </template>
      
      <template v-slot:cell(is_active)="row">
        <div>
          <span v-if="row.item.is_active" class="badge">&#9989;</span>
          <span v-else class="badge" style="color:red"> &#10005; </span>
        </div>
      </template>
      <template v-slot:cell(offer_status)="row">
        <div>
          <span v-if="row.item.offer_status =='8'" class="badge badge-danger">  Not For Sale</span>
          <span v-else class="badge badge-success">  Ready For Sale </span>
        </div>
      </template>
      </b-table>
    </b-card>
    <b-modal id="modal-1" title="Update Single AX" size="lg">
        <b-row>
          <b-col md="6">
            <b-form-group
              id="input-singleAx"
              label="Single Ax"
              label-for="singleAx"
            >
              <b-form-input
                id="premiumAx"
                v-model="form.singleAx"
                type="number"
                placeholder="Enter Single Ax"
                :min="0"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="input-basePrice"
              label="Base Price"
              label-for="basePrice"
            >
              <b-form-input
                id="basePrice"
                v-model="form.basePriceSingle"
                type="number"
                placeholder="Enter Base Price"
                :min="0"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Vendor">
                <multiselect
                  v-model="form.vendorSingle"
                  label="name"
                  track-by="name"
                  placeholder="Type Vendor"
                  open-direction="bottom"
                  :options="vendorSearchOptions"
                  :searchable="true"
                  :loading="isVendorSearch"
                  :close-on-select="true"
                  :options-limit="10"
                  :multiple="false"
                  @search-change="vendorFind"
                  @input="getVendorData"
                  required
                >
                </multiselect>
              </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="input-vendor"
              label="Vendor ID AX"
              label-for="vendorAx"
            >
              <b-form-input
                id="vendorAx"
                v-model="form.vendorAxSingle"
                type="text"
                placeholder="Enter Vendor ax"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      <template #modal-footer>
        <button  class="btn btn-success btn-sm m-1"  @click="actionUpdateSingle()">Update</button>
      </template>
    </b-modal>
    <b-modal id="modal-2" title="Update Premium AX" size="lg">
        <b-row>
          <b-col md="6">
            <b-form-group
              id="input-premiumAx"
              label="Premium Ax"
              label-for="premiumAx"
            >
              <b-form-input
                id="premiumAx"
                v-model="form.premiumAx"
                type="number"
                placeholder="Enter Premium Ax"
                :min="0"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="input-basePrice"
              label="Base Price"
              label-for="basePrice"
            >
              <b-form-input
                id="basePrice"
                v-model="form.basePricePremium"
                type="number"
                placeholder="Enter Base Price"
                :min="0"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Vendor">
                  <multiselect
                    v-model="form.vendorPremium"
                    label="name"
                    track-by="name"
                    placeholder="Type Vendor"
                    open-direction="bottom"
                    :options="vendorSearchOptions"
                    :searchable="true"
                    :loading="isVendorSearch"
                    :close-on-select="true"
                    :options-limit="10"
                    :multiple="false"
                    @search-change="vendorFind"
                    @input="getVendorData"
                    required
                  >
                  </multiselect>
                </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="input-vendor"
                label="Vendor ID AX"
                label-for="vendorAx"
              >
                <b-form-input
                  id="vendorAx"
                  v-model="form.vendorAxPremium"
                  type="text"
                  placeholder="Enter Vendor ax"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        <template #modal-footer>
          <button  class="btn btn-success btn-sm m-1"  @click="actionUpdatePremium()">Update</button>
        </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import constant from "../../store/constant";
import {
  BookEditPath,
  BookPath,
  MagazinePath,
  NewspaperPath,
  AudiobookPath,
  AudioEditPath,
  MagazineEditPath,
  NewspaperEditPath,
  EditCoverItemPath,
  EditPreviewItemPath,
} from "../../router/content";
import { PremiumEditPath, SingleEditPath, SubscriptionEditPath } from "../../router/marketing";
  export default {
    name:"ebookDetail",
    data() {
      return {
        form : {
          premiumAx :0,
          basePricePremium: 0,
          vendorPremium: '',
          vendorAxPremium:'',
          singleAx: 0,
          basePriceSingle: 0,
          vendorSingle: '',
          vendorAxSingle:'',
        },
        item_types_audio: 'audio book',
        vendorSearchOptions: [],
        isVendorSearch: false,
        offers: [],
        now: +new Date(),
        activePreview: null,
        EditCoverItemPath, 
        EditPreviewItemPath,
        SingleEditPath,
        PremiumEditPath,
        SubscriptionEditPath,
        basePathCover:constant.URL_STATIC.EBOOK_COVER,
        basePathPreview:constant.URL_STATIC.EBOOK_PREVIEW,
        listPath: {
          'book': BookPath.name,
          'magazine': MagazinePath.name,
          'newspaper': NewspaperPath.name,
          'audio book': AudiobookPath.name,
        },
        fieldsAudio: [
          { key:'chapter', label: 'Chapter'},
          { key:'title', label: 'Title'},
          { key :'file_time', label:'Duration'},
          { key:'file_name', label: 'File Name'},
          { key :'file_size', label:'File Size'},
          { key:'is_active', label: 'Is Active'},
          { key :'created', label:'Created',formatter: function(value) {
            return moment(value).format('DD-MM-YYYY');
          },},
        ],
        fields: [
          {
            key:'name',
            label: 'Name',
            thStyle: { width: "20%" },
            tdStyle: { width: "20%" }
          },
          { key:'offer_code', label: 'Offer Code'},
          // { key:'item_code', label: 'Item Code'},
          {
            key:'price_idr',
            label: 'Price IDR',
            thClass: 'text-right',
            tdClass: 'text-right'
          },
          {
            key:'price_usd',
            label: 'Price USD',
            thClass: 'text-right',
            tdClass: 'text-right'
          },
          {
            key:'is_free',
            label: 'Is Free',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
          { key:'offer_status',
          label: 'Offer Status',formatter:function(value){
            if (value =='7') {
              return "Ready For Sale"
            } else if (value =='8') {
              return "Not For Sale"
            } else {
              return value
            }
            
          }},
          { 
            key:'is_active', 
            sortable: true, 
            label: 'Active'
          },
          {
            key:'created',
            label: 'Created',
            formatter:function(value) {
              return moment(value).format('DD MMM YYYY')}
          }
        ],
        item_id: null,
        parentalControl: '',
        descParentalControl: '',
      };
    },
    mounted() {
      let id = this.$route.params.id
      this.actionGetEbooks(id);
      this.actionGetSoAx(id);
      this.item_id = id;
    },
    watch: {
      ebooks: function(data) {
        if (!data.id){
          return
        } else{
          this.activePreview = this.basePathCover + this.removeBucket(data.image_highres);
          this.actionGetParentalControl(data.parentalcontrol_id)
          this.getVendorId(parseInt(data.brands?.vendor?.id)).then(response => {
            this.form.vendorSingle = response.data?.data?.rows[0];
            this.form.vendorPremium = response.data?.data?.rows[0];
            this.form.vendorAxSingle = response.data?.data?.rows[0]?.sync_vendor?.vendor_id_ax;
            this.form.vendorAxPremium = response.data?.data?.rows[0]?.sync_vendor?.vendor_id_ax;
          })
          return
        }
        
      }
    
      
    },
    computed: {
      ...mapState({
        vendors: (state) => state.vendors.items,
        ebooks: (state) => state.ebooks.item,
        ItemSoax: (state) => state.ItemSoax.item,
        ItemSoaxPremium: (state) => state.ItemSoax.itemPremium,
        isLoading: (state) => state.ebooks.isLoading,
        isError: (state) => state.ItemSoax.isError,
        audioItem: (state) => state.ebooks.itemAudio,
        isProfileVendor: (state) => state.profile?.vendor_id,
      }),
    },
    created() {
      this.fetchProfile();
    },
    methods: {
      ...mapActions('profile', ['fetchProfile']),
      ...mapActions("vendors", ["searchVendor", "getVendorId"]),
      ...mapActions("ebooks", ["fetchEbookById", 'getParentalControl']),
      ...mapActions("ItemSoax", ["fetch","search","fetchSoAxByItemsId", "updateSoAxByItemsId", "createSoAxByItemsId", 'fetchSoAxByItemsIdPremium', 'updateSoAxPremiumByItemsId']),
      actionGetEbooks(id) {
        let payload = {
          id: id
        };
        this.fetchEbookById(payload)
      },

      actionGetParentalControl(id){
        this.getParentalControl(id)
        .then((res) => {
          this.parentalControl = res.data.data.name
          this.descParentalControl = res.data.data.description
        })
        .catch((err) => {
          if(err.response.status == 422){
            err.response.data.data.errors.map((e) => {
              this.messageAlert('error', e.msg)
            })
          } else {
            this.messageAlert('error', err.response.data.message)
          }
        })
      },

      actionGetSoAx(id) {
        let payload = {
          id: id
        };
        
        this.fetchSoAxByItemsId(payload).then((data) => {
          this.form.singleAx = data?.single_idax ?? 0;
          this.form.basePriceSingle = data?.base_price ?? 0;
        });
        this.fetchSoAxByItemsIdPremium(payload)
        .then((data) => {
          this.form.premiumAx = data?.premium_idax ?? 0;
          this.form.basePricePremium = data?.base_price ?? 0;
        })
      },
      vendorFind(query) {
      if (!query) return;
      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchVendor(payload).then((response) => {
          this.vendorSearchOptions = response.data.data.rows;
          this.isVendorSearch = false;
        }).catch(() => {
          this.isVendorSearch = false;
        });
      }, 600);
      },
      getVendorData(){
        this.form.vendorAx = this.form.vendor?.sync_vendor?.vendor_id_ax

      },
      removeBucket(str) {
        if (str && str.substring(0, 6) == "images") {
          return str.substring(9);
        } else {
          return str ?? 'default.png';
        }
      },
      listObjectToString(data = [], attr) {
        return data.map(item => item[attr]).join(', ');
      },
      itemTypes(type){
        if(type == 'magazine') {
          return 'majalah/';
        }
        if(type == 'newspaper'){
          return 'koran/';
        }
      },
      urlCatalog() {
        const baseURLWeb = 'https://ebooks.gramedia.com/id/';
        const itemSlug = this.ebooks?.slug;
        const brandSlug = this.ebooks?.brands?.slug;

        if (this.ebooks.item_type == constant.ITEM_TYPES.BOOK.toLocaleLowerCase()){
          return baseURLWeb + 'buku/' + itemSlug;
        }
        if (this.ebooks.item_type == constant.ITEM_TYPES.MAGAZINE.toLocaleLowerCase()) {
          const slugEdition = `${itemSlug}`.replace(brandSlug + '-', '');
          return baseURLWeb + 'majalah/' + brandSlug + '/' + slugEdition;
        }
        if (this.ebooks.item_type == constant.ITEM_TYPES.NEWSPAPER.toLocaleLowerCase()) {
          const slugEdition = `${itemSlug}`.replace(brandSlug + '-', '');
          return baseURLWeb + 'koran/' + brandSlug + '/' + slugEdition;
        }

        return baseURLWeb;
      },
      goToEdit() {
        if (this.ebooks.item_type == constant.ITEM_TYPES.BOOK.toLocaleLowerCase()){
          this.$router.push({ path: BookEditPath.path.replace(':id', this.ebooks.id) });
        }
        if (this.ebooks.item_type == constant.ITEM_TYPES.MAGAZINE.toLocaleLowerCase()){
          this.$router.push({ path: MagazineEditPath.path.replace(':id', this.ebooks.id) });
        }
        if (this.ebooks.item_type == constant.ITEM_TYPES.NEWSPAPER.toLocaleLowerCase()){
        this.$router.push({ path: NewspaperEditPath.path.replace(':id', this.ebooks.id) });
        }
        if (this.ebooks.item_type == constant.ITEM_TYPES.AUDIOBOOK.toLocaleLowerCase()){
        this.$router.push({ path: AudioEditPath.path.replace(':id', this.ebooks.id) });
        }
      },
      messageAlert(icon, title, timer=3000) {
        this.$swal({
          toast: "true",
          position: "top-end",
          icon,
          title,
          showConfirmButton: false,
          timer,
          timerProgressBar: true,
        });
      },
      actionUpdateSingle() {
        this.$swal({
          title: "Are you sure?",
          text: "You want Update ?!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update!",
        }).then((result) => {
          if (result.isConfirmed) {
            const data = this.form
            this.updateSoAxByItemsId({ 
              id: this.item_id,
              name: this.ebooks.name,
              single_idax : data.singleAx,
              vendor : data.vendorSingle?.name ?? '',
              vendor_id_ax : data.vendorAxSingle,
              base_price : data.basePriceSingle,
            }).then(async (response) => {
                if (response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    toast: true,
                    position: 'top-end',
                    title: 'Single ID AX Has been Updated',
                    timer: 5000,
                    text: this.message,
                  });
                  let id = this.$route.params.id
                  this.actionGetSoAx(id);
                  this.$bvModal.hide('modal-1');
                } else {
                  this.$swal({
                    icon: 'error',
                    toast: true,
                    position: 'top-end',
                    title: "Something's Error",
                    timer: 3000,
                    text: response,
                  });
                  this.$bvModal.hide('modal-1');
                }
            
            })
            .catch((error) => {
              console.log("error", error.message);
            });
          }
        
			});
      },
      actionUpdatePremium() {
          this.$swal({
            title: "Are you sure?",
            text: "You want Update ?!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Update!",
          }).then((result) => {
            if (result.isConfirmed) {
              const data = this.form
              this.updateSoAxPremiumByItemsId({ 
                id: this.item_id,
                name: this.ebooks.name,
                premium_idax : data.premiumAx,
                vendor : data.vendorPremium?.name ?? '',
                vendor_id_ax : data.vendorAxPremium,
                base_price : data.basePricePremium,
              }).then(async (response) => {
                  if (response.status === 200) {
                    this.$swal({
                      icon: 'success',
                      toast: true,
                      position: 'top-end',
                      title: 'Premium ID AX Has been Updated',
                      timer: 5000,
                      text: this.message,
                    });
                    let id = this.$route.params.id
                    this.actionGetSoAx(id);
                    this.$bvModal.hide('modal-2');
                  } else {
                    this.$swal({
                      icon: 'error',
                      toast: true,
                      position: 'top-end',
                      title: "Something's Error",
                      timer: 3000,
                      text: response,
                    });
                    this.$bvModal.hide('modal-2');
                  }
              
              })
              .catch((error) => {
                console.log("error", error.message);
              });
            }
          
        });
      },
      actionCreate() {
        this.$swal({
          title: "Are you sure?",
          text: "You want Create ?!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Create!",
        }).then((result) => {
          if (result.isConfirmed) {
            const data = this.form
            this.createSoAxByItemsId({ 
              id: this.item_id,
              name: this.ebooks.name,
              premium_idax : data.premiumAx,
              single_idax : data.singleAx,
              vendor : data.vendor.id ?? '',
              vendor_id_ax : data.vendorAx,
              base_price : data.basePrice,
            }).then(async (response) => {
                if (response.status === 200) {
                  this.$swal({
                    icon: 'success',
                    toast: true,
                    position: 'top-end',
                    title: 'Detail Ax Created',
                    timer: 5000,
                    text: this.message,
                  });
                  
                let id = this.$route.params.id
                this.actionGetSoAx(id);
                this.$bvModal.hide('modal-1');
                } else {
                  this.$swal({
                    icon: 'error',
                    toast: true,
                    position: 'top-end',
                    title: "Something's Error",
                    timer: 3000,
                    text: response,
                  });
                  this.$bvModal.hide('modal-1');
                }
            })
            .catch((error) => {
              console.log("error", error.message);
            });
          }
        
        });
      },
      actionEditChapter(){
        this.$router.push({
          path: `/audio/editChapter/${this.item_id}`,
        });
      },
      srcPreview(page) {
        return this.basePathPreview +
          this.ebooks.brand_id + '/' +
          this.ebooks.id + '/' +
          page + '.jpg';
      },
      selectedPreview(page) { 
        this.activePreview = this.srcPreview(page);
      },
      isShowPreview() {
        const typeBook = constant.ITEM_TYPES.BOOK.toLowerCase();
        const typeMagazine = constant.ITEM_TYPES.MAGAZINE.toLowerCase();
        if (!this.ebooks?.page_count) return false;
        if (this.ebooks.item_type == typeBook){
          return true;
        }if (this.ebooks.item_type == typeMagazine){
          return true;
        }
        return false;
      },
    },
  }
</script>

<style lang="css" scoped>
.img-sm {
  height: 200px;
  width: auto;
  cursor: pointer;
  transition: transform .2s;
}
.img-sm:hover {
  box-shadow: 0 0 15px 0 grey !important;
  transform: scale(3);
}
#cover-preview {
  z-index: 1;
}
</style>
