var render = function () {
  var _vm$ebooks$preview_pa, _vm$ItemSoaxPremium, _vm$ItemSoaxPremium2, _vm$ItemSoaxPremium3, _vm$ItemSoaxPremium4, _vm$ItemSoax, _vm$ItemSoax2, _vm$ItemSoaxPremium5, _vm$ItemSoaxPremium6, _vm$ItemSoax3, _vm$ItemSoax4, _vm$ItemSoaxPremium7, _vm$ItemSoaxPremium8, _vm$ItemSoax5, _vm$ItemSoax6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mt-4"
  }, [_c('i', {
    staticClass: "fa fa-backward"
  }), _c('router-link', {
    staticClass: "text-capitalize",
    attrs: {
      "to": {
        name: _vm.listPath[_vm.ebooks.item_type]
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_type) + " ")]), !_vm.ebooks.id && !_vm.isLoading ? _c('h3', {
    staticClass: "col text-center"
  }, [_vm._v("No Item Found")]) : _c('h1', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.ebooks.item_type.toUpperCase()) + " 'S ITEM")])], 1), _c('b-card', {
    staticClass: "p-2",
    attrs: {
      "id": "detil-item"
    }
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Name")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.name) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Edition Code")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.edition_code) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Vendor")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.brands ? _vm.ebooks.brands.vendor.name : '') + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Authors")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.listObjectToString(_vm.ebooks.authors, 'name')) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Brands")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_c('b', [_vm._v("#" + _vm._s(_vm.ebooks.brand_id))]), _vm._v(" " + _vm._s(_vm.ebooks.brands ? _vm.ebooks.brands.name : '') + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Release Date")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.datetime24H(_vm.ebooks.release_date)) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Release Schedule")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.datetime24H(_vm.ebooks.release_schedule)) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Categories")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.listObjectToString(_vm.ebooks.categories, 'name')) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Active")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.is_active ? '✅' : '❌') + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Internal Content")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.is_internal_content ? '✅' : '❌') + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Retail")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.category_ax ? _vm.ebooks.category_ax.is_retail ? '✅' : '❌' : '') + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Parental Control")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.parentalControl) + " (" + _vm._s(_vm.descParentalControl) + ") ")])], 1), !_vm.isProfileVendor ? _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Category AX")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.category_ax ? _vm.ebooks.category_ax.category_ax : '-') + " ")])], 1) : _vm._e(), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Status")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.item_status) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Description")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.ebooks.description)
    }
  })], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Current File")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("fileSizeFormat")(_vm.ebooks.file_size)) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Content Type")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ebooks.content_type) + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "3",
      "lg": "2"
    }
  }, [_vm._v("Link Preview")]), _vm.ebooks.item_status == 'ready for consume' ? _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.urlCatalog(_vm.ebooks),
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(_vm._s(_vm.urlCatalog(_vm.ebooks)))])]) : _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_c('span', [_vm._v(" Link preview not yet showing up, because not ready for consume ")])])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "text-center mt-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.goToEdit
    }
  }, [_vm._v("Edit Detail")])], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "id": "cover-preview"
    }
  }, [_c('b-row', {
    attrs: {
      "id": "row-cover"
    }
  }, [_c('h3', {
    staticClass: "col-12",
    staticStyle: {
      "z-index": "-1"
    }
  }, [_vm._v("Cover")]), _c('b-col', {
    staticClass: "text-center"
  }, [_c('b-card-img', {
    staticClass: "img-sm text-center rounded cover m-2",
    attrs: {
      "title": "image_normal",
      "src": _vm.basePathCover + _vm.removeBucket(_vm.ebooks.image_normal) + '?' + _vm.now
    }
  }), _c('b-card-img', {
    staticClass: "img-sm text-center rounded cover m-2",
    attrs: {
      "title": "image_highres",
      "src": _vm.basePathCover + _vm.removeBucket(_vm.ebooks.image_highres) + '?' + _vm.now
    }
  }), _c('b-card-img', {
    staticClass: "img-sm text-center rounded cover m-2",
    attrs: {
      "title": "thumb_image_normal",
      "src": _vm.basePathCover + _vm.removeBucket(_vm.ebooks.thumb_image_normal) + '?' + _vm.now
    }
  }), _c('b-card-img', {
    staticClass: "img-sm text-center rounded cover m-2",
    attrs: {
      "title": "thumb_image_highres",
      "src": _vm.basePathCover + _vm.removeBucket(_vm.ebooks.thumb_image_highres) + '?' + _vm.now
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "pt-2 justify-content-center",
    attrs: {
      "id": "btn-edit-cover"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "to": {
        name: _vm.EditCoverItemPath.name,
        params: {
          id: _vm.ebooks.id
        }
      }
    }
  }, [_vm._v("Edit Cover")])], 1), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('span', [_vm._v("Max Upload 10MB")])]), _c('hr'), _vm.isShowPreview() ? _c('div', {
    attrs: {
      "id": "row-preview"
    }
  }, [_c('b-row', [_c('h3', {
    staticClass: "col-12",
    staticStyle: {
      "z-index": "-1"
    }
  }, [_vm._v("Preview")])]), _vm._l(_vm.ebooks.preview_page, function (page) {
    return _c('span', {
      key: page
    }, [_c('b-card-img', {
      staticClass: "img-sm shadow rounded m-2",
      attrs: {
        "src": _vm.srcPreview(page) + '?' + _vm.now,
        "alt": "Image"
      },
      on: {
        "click": function ($event) {
          return _vm.selectedPreview(page);
        }
      }
    })], 1);
  }), (_vm$ebooks$preview_pa = _vm.ebooks.preview_page) !== null && _vm$ebooks$preview_pa !== void 0 && _vm$ebooks$preview_pa.length ? _c('b-row', {
    staticClass: "pt-2 justify-content-center",
    attrs: {
      "id": "btn-edit-preview"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "to": {
        name: _vm.EditPreviewItemPath.name,
        params: {
          id: _vm.ebooks.id
        }
      }
    }
  }, [_vm._v("Edit Preview")])], 1) : _vm._e()], 2) : _vm._e()], 1), !_vm.isProfileVendor ? _c('b-card', {
    attrs: {
      "id": "item-ax"
    }
  }, [_c('h4', {
    staticClass: "card-title text-center h3 p-1"
  }, [_vm._v(" Item AX ")]), _c('hr'), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_vm._v("Premium Ax :")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s((_vm$ItemSoaxPremium = _vm.ItemSoaxPremium) !== null && _vm$ItemSoaxPremium !== void 0 && _vm$ItemSoaxPremium.premium_idax ? (_vm$ItemSoaxPremium2 = _vm.ItemSoaxPremium) === null || _vm$ItemSoaxPremium2 === void 0 ? void 0 : _vm$ItemSoaxPremium2.premium_idax : "Don't have premium ax yet") + " ")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_vm._v("Single Ax :")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.ItemSoax ? _vm.ItemSoax.single_idax : "Don't have single ax yet") + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_vm._v("Base Price :")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s((_vm$ItemSoaxPremium3 = _vm.ItemSoaxPremium) !== null && _vm$ItemSoaxPremium3 !== void 0 && _vm$ItemSoaxPremium3.base_price ? (_vm$ItemSoaxPremium4 = _vm.ItemSoaxPremium) === null || _vm$ItemSoaxPremium4 === void 0 ? void 0 : _vm$ItemSoaxPremium4.base_price : "Don't base price yet") + " ")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_vm._v("Base Price :")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s((_vm$ItemSoax = _vm.ItemSoax) !== null && _vm$ItemSoax !== void 0 && _vm$ItemSoax.base_price ? (_vm$ItemSoax2 = _vm.ItemSoax) === null || _vm$ItemSoax2 === void 0 ? void 0 : _vm$ItemSoax2.base_price : "Don't base price yet") + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_vm._v("Vendor :")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s((_vm$ItemSoaxPremium5 = _vm.ItemSoaxPremium) !== null && _vm$ItemSoaxPremium5 !== void 0 && _vm$ItemSoaxPremium5.vendor ? (_vm$ItemSoaxPremium6 = _vm.ItemSoaxPremium) === null || _vm$ItemSoaxPremium6 === void 0 ? void 0 : _vm$ItemSoaxPremium6.vendor : "Don't have vendor yet") + " ")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_vm._v("Vendor :")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s((_vm$ItemSoax3 = _vm.ItemSoax) !== null && _vm$ItemSoax3 !== void 0 && _vm$ItemSoax3.vendor ? (_vm$ItemSoax4 = _vm.ItemSoax) === null || _vm$ItemSoax4 === void 0 ? void 0 : _vm$ItemSoax4.vendor : "Don't have vendor yet") + " ")])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_vm._v("Vendor Ax :")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s((_vm$ItemSoaxPremium7 = _vm.ItemSoaxPremium) !== null && _vm$ItemSoaxPremium7 !== void 0 && _vm$ItemSoaxPremium7.vendor_id_ax ? (_vm$ItemSoaxPremium8 = _vm.ItemSoaxPremium) === null || _vm$ItemSoaxPremium8 === void 0 ? void 0 : _vm$ItemSoaxPremium8.vendor_id_ax : "Don't have vendor ax yet") + " ")]), _c('b-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "md": "6",
      "lg": "2"
    }
  }, [_vm._v("Vendor Ax :")]), _c('b-col', {
    attrs: {
      "offset": "1",
      "offset-md": "0"
    }
  }, [_vm._v(" " + _vm._s((_vm$ItemSoax5 = _vm.ItemSoax) !== null && _vm$ItemSoax5 !== void 0 && _vm$ItemSoax5.vendor_id_ax ? (_vm$ItemSoax6 = _vm.ItemSoax) === null || _vm$ItemSoax6 === void 0 ? void 0 : _vm$ItemSoax6.vendor_id_ax : "Don't have vendor ax yet") + " ")])], 1), _c('div', {
    staticClass: "row mt-5"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-2",
      modifiers: {
        "modal-2": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v("Update Premium AX")])], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-1",
      modifiers: {
        "modal-1": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v("Update Single AX")])], 1)])], 1) : _vm._e(), _vm.ebooks.item_type == _vm.item_types_audio ? _c('b-card', {
    attrs: {
      "id": "chapter-audio"
    }
  }, [_c('h4', {
    staticClass: "card-title text-center h3 p-1"
  }, [_vm._v(" Chapter [Audio Only] ")]), _c('b-table', {
    attrs: {
      "fields": _vm.fieldsAudio,
      "hover": "",
      "responsive": "sm",
      "striped": true,
      "items": _vm.audioItem,
      "show-empty": true
    },
    scopedSlots: _vm._u([{
      key: "cell(is_active)",
      fn: function (row) {
        return [_c('div', [row.item.is_active ? _c('span', {
          staticClass: "badge"
        }, [_vm._v("✅")]) : _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ✕ ")])])];
      }
    }, {
      key: "cell(file_size)",
      fn: function (row) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm._f("fileSizeFormat")(row.item.file_size)) + " ")])];
      }
    }], null, false, 3191024010)
  }), _vm.ebooks.item_status == 'ready for upload' ? _c('b-col', [_c('b-button', {
    staticClass: "font-weight-bold",
    attrs: {
      "variant": "primary",
      "md": "6",
      "lg": "2"
    },
    on: {
      "click": _vm.actionEditChapter
    }
  }, [_vm._v("Edit Chapter")])], 1) : _c('b-col', [_c('b-button', {
    staticClass: "font-weight-bold",
    attrs: {
      "variant": "primary",
      "md": "6",
      "lg": "2"
    },
    on: {
      "click": _vm.actionEditChapter
    }
  }, [_vm._v("View Chapter")])], 1)], 1) : _vm._e(), _c('b-card', {
    attrs: {
      "id": "offer-single"
    }
  }, [_c('h4', {
    staticClass: "card-title text-center h3 p-1"
  }, [_vm._v(" Offers Single ")]), _c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "hover": "",
      "responsive": "sm",
      "striped": true,
      "items": _vm.ebooks.single,
      "show-empty": true
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: _vm.SingleEditPath.name,
              params: {
                id: row.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])];
      }
    }, {
      key: "cell(is_free)",
      fn: function (row) {
        return [_c('div', [row.item.is_free ? _c('span', {
          staticClass: "badge"
        }, [_vm._v("✅")]) : _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ❌ ")])])];
      }
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [_c('div', [row.item.is_active ? _c('span', {
          staticClass: "badge"
        }, [_vm._v("✅")]) : _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ✕ ")])])];
      }
    }, {
      key: "cell(offer_status)",
      fn: function (row) {
        return [_c('div', [row.item.offer_status == '8' ? _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v(" Not For Sale")]) : _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" Ready For Sale ")])])];
      }
    }])
  })], 1), _c('b-card', {
    attrs: {
      "id": "offer-buffets"
    }
  }, [_c('h4', {
    staticClass: "card-title h3 text-center p-1"
  }, [_vm._v(" Offers Buffets ")]), _c('b-table', {
    attrs: {
      "hover": "",
      "fields": _vm.fields,
      "responsive": "sm",
      "items": _vm.ebooks.buffets,
      "show-empty": true
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function (row) {
        return [row.item.offer_type_id == 2 ? _c('span', [_c('router-link', {
          attrs: {
            "to": {
              name: _vm.SubscriptionEditPath.name,
              params: {
                id: row.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])], 1) : _c('span', [_c('router-link', {
          attrs: {
            "to": {
              name: _vm.PremiumEditPath.name,
              params: {
                id: row.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])], 1)];
      }
    }, {
      key: "cell(is_free)",
      fn: function (row) {
        return [_c('div', [row.item.is_free ? _c('span', {
          staticClass: "badge"
        }, [_vm._v("✅")]) : _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ❌ ")])])];
      }
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [_c('div', [row.item.is_active ? _c('span', {
          staticClass: "badge"
        }, [_vm._v("✅")]) : _c('span', {
          staticClass: "badge",
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ✕ ")])])];
      }
    }, {
      key: "cell(offer_status)",
      fn: function (row) {
        return [_c('div', [row.item.offer_status == '8' ? _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v(" Not For Sale")]) : _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" Ready For Sale ")])])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-1",
      "title": "Update Single AX",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('button', {
          staticClass: "btn btn-success btn-sm m-1",
          on: {
            "click": function ($event) {
              return _vm.actionUpdateSingle();
            }
          }
        }, [_vm._v("Update")])];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-singleAx",
      "label": "Single Ax",
      "label-for": "singleAx"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "premiumAx",
      "type": "number",
      "placeholder": "Enter Single Ax",
      "min": 0,
      "required": ""
    },
    model: {
      value: _vm.form.singleAx,
      callback: function ($$v) {
        _vm.$set(_vm.form, "singleAx", $$v);
      },
      expression: "form.singleAx"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-basePrice",
      "label": "Base Price",
      "label-for": "basePrice"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "basePrice",
      "type": "number",
      "placeholder": "Enter Base Price",
      "min": 0,
      "required": ""
    },
    model: {
      value: _vm.form.basePriceSingle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "basePriceSingle", $$v);
      },
      expression: "form.basePriceSingle"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vendor"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type Vendor",
      "open-direction": "bottom",
      "options": _vm.vendorSearchOptions,
      "searchable": true,
      "loading": _vm.isVendorSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false,
      "required": ""
    },
    on: {
      "search-change": _vm.vendorFind,
      "input": _vm.getVendorData
    },
    model: {
      value: _vm.form.vendorSingle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorSingle", $$v);
      },
      expression: "form.vendorSingle"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-vendor",
      "label": "Vendor ID AX",
      "label-for": "vendorAx"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vendorAx",
      "type": "text",
      "placeholder": "Enter Vendor ax",
      "required": ""
    },
    model: {
      value: _vm.form.vendorAxSingle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorAxSingle", $$v);
      },
      expression: "form.vendorAxSingle"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-2",
      "title": "Update Premium AX",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('button', {
          staticClass: "btn btn-success btn-sm m-1",
          on: {
            "click": function ($event) {
              return _vm.actionUpdatePremium();
            }
          }
        }, [_vm._v("Update")])];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-premiumAx",
      "label": "Premium Ax",
      "label-for": "premiumAx"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "premiumAx",
      "type": "number",
      "placeholder": "Enter Premium Ax",
      "min": 0
    },
    model: {
      value: _vm.form.premiumAx,
      callback: function ($$v) {
        _vm.$set(_vm.form, "premiumAx", $$v);
      },
      expression: "form.premiumAx"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-basePrice",
      "label": "Base Price",
      "label-for": "basePrice"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "basePrice",
      "type": "number",
      "placeholder": "Enter Base Price",
      "min": 0,
      "required": ""
    },
    model: {
      value: _vm.form.basePricePremium,
      callback: function ($$v) {
        _vm.$set(_vm.form, "basePricePremium", $$v);
      },
      expression: "form.basePricePremium"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vendor"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Type Vendor",
      "open-direction": "bottom",
      "options": _vm.vendorSearchOptions,
      "searchable": true,
      "loading": _vm.isVendorSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false,
      "required": ""
    },
    on: {
      "search-change": _vm.vendorFind,
      "input": _vm.getVendorData
    },
    model: {
      value: _vm.form.vendorPremium,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorPremium", $$v);
      },
      expression: "form.vendorPremium"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-vendor",
      "label": "Vendor ID AX",
      "label-for": "vendorAx"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vendorAx",
      "type": "text",
      "placeholder": "Enter Vendor ax",
      "required": ""
    },
    model: {
      value: _vm.form.vendorAxPremium,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vendorAxPremium", $$v);
      },
      expression: "form.vendorAxPremium"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }